import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import {
    GetDimensionValuesHook,
    LoadCustomerJourneyHook,
    LoadCustomerJourneyPageConfigHook,
    LoadDataHook,
    LoadPaginationHook,
    LoadPixelHook,
    Provider,
} from "domain/ConversionList/context/ConversionListExternalDependencyContext"
import {
    mapCustomerJourneyFiltersToConditionClause,
    mapFiltersToConditionClause,
} from "domain/ConversionList/domain/filters"
import { TouchpointFilterDimension } from "domain/ConversionList/domain/touchpointFilterDimension"
import DimensionService, { asDataColumnIdentifier } from "domain/dimension/service/DimensionService"
import {
    ConversionListOptionalSettingsDTO,
    ConversionListOptionalSettingsDTOListTypeEnum,
    ConversionPixelRequestParamsDTO,
    QuerySettingsDTO,
} from "generated/models"
import { AppContextDTO, ContainerElementDTO } from "generated/models"
import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "shared/redux/store"
import FilterServiceClient from "shared/service/FilterServiceClient"
import ReportingServiceClient from "shared/service/ReportingServiceClient"
import { TimeUtil } from "shared/util/TimeUtil"
import { log } from "shared/util/log"
import { match } from "ts-pattern"
import { v1 as uuid } from "uuid"

const INT32_MAX_VALUE = 2147483647

const FILTER_DIMENSION_COLUMN_NAMES: Record<TouchpointFilterDimension, string[]> = {
    channel: [
        DimensionService.getNameColumn(asDataColumnIdentifier("channel")),
        DimensionService.getValueColumn(asDataColumnIdentifier("channel")),
    ],
    sub_campaign: [
        DimensionService.getNameColumn(asDataColumnIdentifier("sub_campaign")),
        DimensionService.getValueColumn(asDataColumnIdentifier("sub_campaign")),
    ],
    line_item: [
        DimensionService.getNameColumn(asDataColumnIdentifier("line_item")),
        DimensionService.getValueColumn(asDataColumnIdentifier("line_item")),
    ],
    publisher: [
        DimensionService.getNameColumn(asDataColumnIdentifier("publisher")),
        DimensionService.getValueColumn(asDataColumnIdentifier("publisher")),
    ],
}

export interface ConversionListExternalDependencyContextProps {
    children: React.ReactNode
}

export const ConversionListExternalDependencyContextProvider = ({
    children,
}: ConversionListExternalDependencyContextProps) => {
    const appContext: AppContextDTO = useSelector((state: RootState) => state.appContext.appContext)

    const useDimensionValuesQuery: GetDimensionValuesHook = ({ touchpointFilterDimension }) => {
        return useQuery({
            queryKey: ["dimensionValues", touchpointFilterDimension, appContext],
            queryFn: async () => {
                const result = await FilterServiceClient.loadDimensionValues({
                    appContext,
                    columnNames: FILTER_DIMENSION_COLUMN_NAMES[touchpointFilterDimension],
                    paginationSettings: {
                        pageSize: INT32_MAX_VALUE,
                        page: 0,
                    },
                    sortSettings: {
                        sortProperties: [],
                        sortAscending: true,
                    },
                    queryIdentifier: { value: uuid() },
                })
                return result.entries
                    .filter((entry) => entry.name !== undefined && entry.value !== "__NULL__")
                    .map((entry) => ({
                        name: entry.name,
                        value: entry.value,
                    }))
            },
            enabled: !!touchpointFilterDimension,
            gcTime: 0,
        })
    }

    const useLoadDataQuery: LoadDataHook = ({ queryConfig, paginationSettings, sortSettings }) => {
        const type = ConversionListContextSelectors.useConversionListType()
        const selectedColumns = ConversionListContextSelectors.useSelectedColumns()

        const loadData = match(type)
            .with("historical", () => ReportingServiceClient.loadHistoricalConversionListData)
            .with("realtime", () => ReportingServiceClient.loadRealtimeConversionListData)
            .exhaustive()

        const enumType = match(type)
            .with("historical", () => ConversionListOptionalSettingsDTOListTypeEnum.HISTORICAL)
            .with("realtime", () => ConversionListOptionalSettingsDTOListTypeEnum.REAL_TIME)
            .exhaustive()

        const [start, end] = queryConfig.timeRange
        const optionalSettings: ConversionListOptionalSettingsDTO = {
            type: "com.exactag.reporting.conversionlist.infrastructure.dto.ConversionListOptionalSettingsDTO",
            showBlockedTouchpoints: queryConfig.customerJourneyConfig.showBlockedTouchpoints,
            listType: enumType,
        }
        const querySettings: QuerySettingsDTO = {
            appContext,
            // request only selected columns (note that the backend currently loads & sends all columns anyway)
            columnNames: selectedColumns as string[],
            timespanSettings: { start: TimeUtil.toISOLocalString(start!), end: TimeUtil.toISOLocalString(end!) },
            sortSettings: sortSettings,
            paginationSettings: {
                pageSize: paginationSettings.pageSize,
                page: paginationSettings.page,
            },
            optionalSettings: optionalSettings,
            filter: mapFiltersToConditionClause(queryConfig),
            // queryIdentifier will be set later in the queryFn
            // because querySettings is used as a key in the queryKey and we don't want to change the key on every render
            queryIdentifier: { value: "" },
        }

        return useQuery({
            queryKey: ["loadData", type, querySettings],
            queryFn: async ({ signal }) => {
                log.debug("loadDataQuery", querySettings)

                return await loadData({ ...querySettings, queryIdentifier: { value: uuid() } }, signal)
            },
            enabled: true,
            placeholderData: keepPreviousData,
        })
    }

    const useLoadPaginationQuery: LoadPaginationHook = ({ enabled, queryConfig, paginationSettings, sortSettings }) => {
        const type = ConversionListContextSelectors.useConversionListType()
        const selectedColumns = ConversionListContextSelectors.useSelectedColumns()

        const loadData = match(type)
            .with("historical", () => ReportingServiceClient.loadHistoricalPaginationListData)
            .with("realtime", () => ReportingServiceClient.loadRealtimePaginationListData)
            .exhaustive()

        const enumType = match(type)
            .with("historical", () => ConversionListOptionalSettingsDTOListTypeEnum.HISTORICAL)
            .with("realtime", () => ConversionListOptionalSettingsDTOListTypeEnum.REAL_TIME)
            .exhaustive()

        const [start, end] = queryConfig.timeRange
        const optionalSettings = {
            type: "com.exactag.reporting.conversionlist.infrastructure.dto.ConversionListOptionalSettingsDTO",
            showBlockedTouchpoints: queryConfig.customerJourneyConfig.showBlockedTouchpoints,
            listType: enumType,
        } satisfies ConversionListOptionalSettingsDTO
        const querySettings: QuerySettingsDTO = {
            appContext,
            // request only selected columns (note that the backend currently loads & sends all columns anyway)
            columnNames: selectedColumns as string[],
            timespanSettings: { start: TimeUtil.toISOLocalString(start!), end: TimeUtil.toISOLocalString(end!) },
            sortSettings: sortSettings,
            paginationSettings: {
                pageSize: paginationSettings.pageSize,
                page: 0, // the page parameter will not be used, to hardcode it avoids sending the request again unnecessarily
            },
            optionalSettings: optionalSettings,
            filter: mapFiltersToConditionClause(queryConfig),
            // queryIdentifier will be set later in the queryFn
            // because querySettings is used as a key in the queryKey and we don't want to change the key on every render
            queryIdentifier: { value: "" },
        }

        return useQuery({
            queryKey: ["loadPagination", type, querySettings],
            queryFn: async ({ signal }) => {
                log.debug("loadDataQuery", querySettings)

                return await loadData({ ...querySettings, queryIdentifier: { value: uuid() } }, signal)
            },
            enabled: enabled,
            placeholderData: keepPreviousData,
        })
    }

    const useLoadCustomerJourneyPageConfigQuery: LoadCustomerJourneyPageConfigHook = ({ appContext }) => {
        return useQuery({
            queryKey: ["loadCustomerJourneyPageConfig", appContext],
            queryFn: async ({ signal }) => {
                const pageConfig = await ReportingServiceClient.loadCustomerJourneyPageConfig(
                    {
                        appContext,
                    },
                    signal,
                )
                return [...(pageConfig.layoutConfig as ContainerElementDTO).children][0]
            },
            enabled: true,
            placeholderData: keepPreviousData,
        })
    }

    const useLoadCustomerJourneyQuery: LoadCustomerJourneyHook = ({ queryConfig, transactionUid }) => {
        const type = ConversionListContextSelectors.useConversionListType()
        const enumType = match(type)
            .with("historical", () => ConversionListOptionalSettingsDTOListTypeEnum.HISTORICAL)
            .with("realtime", () => ConversionListOptionalSettingsDTOListTypeEnum.REAL_TIME)
            .exhaustive()

        return useQuery({
            queryKey: ["loadCustomerJourney", queryConfig, transactionUid, appContext],
            queryFn: async ({ signal }) => {
                const [start, end] = queryConfig.timeRange
                const optionalSettings = {
                    type: "com.exactag.reporting.conversionlist.infrastructure.dto.ConversionListOptionalSettingsDTO",
                    showBlockedTouchpoints: queryConfig.customerJourneyConfig.showBlockedTouchpoints,
                    cjMode: queryConfig.customerJourneyConfig.cjMode,
                    listType: enumType,
                } satisfies ConversionListOptionalSettingsDTO
                return await ReportingServiceClient.loadCustomerJourneyData(
                    {
                        appContext,
                        // At the moment, columnNames are ignored by the backend for customer journey queries
                        columnNames: [],
                        timespanSettings: {
                            start: TimeUtil.toISOLocalString(start!),
                            end: TimeUtil.toISOLocalString(end!),
                        },
                        paginationSettings: {
                            pageSize: INT32_MAX_VALUE,
                            page: 0,
                        },
                        optionalSettings: optionalSettings,
                        filter: mapCustomerJourneyFiltersToConditionClause(queryConfig, transactionUid),
                        queryIdentifier: { value: uuid() },
                    },
                    signal,
                )
            },
            enabled: true,
            placeholderData: keepPreviousData,
        })
    }

    const useLoadPixelQuery: LoadPixelHook = ({ campaignId, transactionUid, timestamp }) => {
        return useQuery({
            queryKey: ["conversionPixels", transactionUid, timestamp, appContext],
            queryFn: async ({ signal }) => {
                return await ReportingServiceClient.loadPixelData(
                    {
                        appContext,
                        campaignId,
                        transactionUid,
                        transactionTs: timestamp,
                        //queryIdentifier: { value: uuid() },
                    } satisfies ConversionPixelRequestParamsDTO,
                    signal,
                )
            },
        })
    }

    const value = {
        appContext,
        useDimensionValuesQuery,
        useLoadDataQuery,
        useLoadCustomerJourneyPageConfigQuery,
        useLoadCustomerJourneyQuery,
        useLoadPixelQuery,
        useLoadPaginationQuery,
    }

    return <Provider value={value}>{children}</Provider>
}
