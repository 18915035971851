import { PropertyFilterChipTooltip, StyledTooltip } from "./PropertyFilterChipTooltip"
import ClearIcon from "@mui/icons-material/Clear"
import EditRoundedIcon from "@mui/icons-material/EditRounded"
import { Box, Paper, Typography, styled } from "@mui/material"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import React from "react"

export type PropertyFilterChipPropsStructured = {
    role: string // The role (Winner, Closer, etc.)
    dimension: string // The dimension (Line Item, Sub Campaign, etc.)
    values: string[] // Array of filter values
    onDelete: () => void
    onEdit: () => void
    label?: never // Make sure label isn't used with structured props
}

export type PropertyFilterChipPropsLabel = {
    label: string // The complete label string (used for backward compatibility)
    onDelete: () => void
    onEdit: () => void
    role?: never // Make sure role isn't used with label
    dimension?: never // Make sure dimension isn't used with label
    values?: never // Make sure values isn't used with label
}

// Union type to allow either structured or label props
export type PropertyFilterChipProps = PropertyFilterChipPropsStructured | PropertyFilterChipPropsLabel

// Custom component for the chip header
const ChipHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-end", // Changed from 'center' to 'flex-end' to align to bottom
    marginBottom: "0px", // Removed the margin here
})

// Component for the chip title (role is dimension)
const ChipTitle = styled(Typography)({
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "1.3em",
    paddingBottom: "0px",
})

// Style for the bold text parts (role and dimension)
const BoldSpan = styled("span")({
    fontWeight: "bold",
})

// Component for filter values
const FilterValues = styled(Typography)(({ theme: _ }) => ({
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.3em",
    maxHeight: "5.2em", // 4 lines * 1.3em line height
    wordBreak: "break-all",
    fontSize: "12px",
    width: "100%",
}))

// Styled Paper component to replace the Chip
const StyledChipContainer = styled(Paper)(({ theme: _ }) => ({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "6px 12px 8px 12px", // Asymmetrical padding: top 6px, right 12px, bottom 8px, left 12px
    width: "100%",
    marginBottom: "8px",
    boxShadow: `0px 0px 0px 1px rgb(0 0 0 / 0.04),
      0px 1px 1px -0.5px rgb(0 0 0 / 0.03),
      0px 2px 2px -1px rgb(0 0 0 / 0.03), 
      0px 4px 4px -2px rgb(0 0 0 / 0.02)`,
}))

// Container for the action icons
const ActionsContainer = styled("div")({
    display: "flex",
    alignItems: "center",
})

export const PropertyFilterChip: React.FC<PropertyFilterChipProps> = (props) => {
    // Format the display for the header and values sections
    const renderFormattedContent = () => {
        // If using the legacy 'label' prop, just return it
        if ("label" in props && props.label) {
            // Try to find role and dimension pattern in the label string for formatting
            const match = props.label.match(/^(.*?) is (.*?) (.*)/)
            if (match) {
                const [, role, dimension, values] = match
                return {
                    header: (
                        <>
                            <BoldSpan>{role}</BoldSpan> is {dimension}
                        </>
                    ),
                    values: values,
                }
            }
            return {
                header: "Filter",
                values: props.label,
            }
        }

        // Using structured props
        if ("role" in props && "dimension" in props && "values" in props && props.values) {
            // First 5 values are shown, rest are summarized
            const MAX_SHOWN_VALUES = 5

            let displayValues
            if (props.values.length <= MAX_SHOWN_VALUES) {
                displayValues = props.values.join(", ")
            } else {
                const shownValues = props.values.slice(0, MAX_SHOWN_VALUES)
                const moreCount = props.values.length - MAX_SHOWN_VALUES
                displayValues = `${shownValues.join(", ")} +${moreCount} more`
            }

            // Only role in bold for the chip header
            return {
                header: (
                    <>
                        <BoldSpan>{props.role}</BoldSpan> is {props.dimension}
                    </>
                ),
                values: displayValues,
            }
        }

        // Fallback case - should never happen with proper typing
        return {
            header: "Invalid",
            values: "Invalid props",
        }
    }

    const content = renderFormattedContent()

    return (
        <StyledTooltip title={<PropertyFilterChipTooltip {...props} />} placement="left" arrow>
            <StyledChipContainer className="touchpoint-property-filter-chip">
                <ChipHeader>
                    <ChipTitle>{content.header}</ChipTitle>
                    <ActionsContainer>
                        <HoverIconButton size="small" onClick={props.onEdit} sx={{ padding: 0.3 }}>
                            <EditRoundedIcon fontSize="small" sx={{ fontSize: "16px" }} />
                        </HoverIconButton>
                        <HoverIconButton size="small" onClick={props.onDelete} sx={{ padding: 0.3 }}>
                            <ClearIcon fontSize="small" sx={{ fontSize: "16px" }} />
                        </HoverIconButton>
                    </ActionsContainer>
                </ChipHeader>
                <FilterValues>{content.values}</FilterValues>
            </StyledChipContainer>
        </StyledTooltip>
    )
}

interface HoverIconButtonProps extends IconButtonProps {
    hovered?: boolean // Made optional since we no longer need it
}

const HoverIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== "hovered",
})<HoverIconButtonProps>(({ theme }) => ({
    color: theme.palette.text.secondary,
    "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
    },
}))
