import { GridRowModel } from "@mui/x-data-grid-pro"
import { ColumnField } from "domain/dimension/service/DimensionService"
import { ColumnResponseDTO } from "generated/models"
import * as React from "react"

export const currencyMetricRenderer = (value: ColumnResponseDTO, row: GridRowModel<any>) => {
    const CURRENCY_IDENTIFIER = "currency"

    const currencyCode = row[ColumnField.valueField(CURRENCY_IDENTIFIER).toString()].value
    return (
        <div className={"is-metric"}>
            {value?.value} {getCurrencySymbol(currencyCode)}
        </div>
    )
}

const getCurrencySymbol = (code: string): string => {
    switch (code) {
        case "AFN":
            return "؋"
        case "ALL":
            return "Lek"
        case "ANG":
            return "ƒ"
        case "ARS":
            return "$"
        case "AUD":
            return "$"
        case "AWG":
            return "ƒ"
        case "AZN":
            return "ман"
        case "BAM":
            return "KM"
        case "BBD":
            return "$"
        case "BGN":
            return "лв"
        case "BMD":
            return "$"
        case "BND":
            return "$"
        case "BOB":
            return "$b"
        case "BRL":
            return "R$"
        case "BSD":
            return "$"
        case "BWP":
            return "P"
        case "BYR":
            return "p."
        case "BZD":
            return "BZ$"
        case "CAD":
            return "$"
        case "CHF":
            return "CHF"
        case "CLP":
            return "$"
        case "CNY":
            return "¥"
        case "COP":
            return "$"
        case "CRC":
            return "₡"
        case "CUP":
            return "₱"
        case "CZK":
            return "Kč"
        case "DKK":
            return "kr"
        case "DOP":
            return "RD$"
        case "EGP":
            return "£"
        case "EUR":
            return "€"
        case "FJD":
            return "$"
        case "FKP":
            return "£"
        case "GBP":
            return "£"
        case "GTQ":
            return "Q"
        case "HKD":
            return "$"
        case "HNL":
            return "L"
        case "HRK":
            return "kn"
        case "HUF":
            return "Ft"
        case "IDR":
            return "Rp"
        case "ILS":
            return "₪"
        case "INR":
            return "₹"
        case "ISK":
            return "kr"
        case "JMD":
            return "J$"
        case "JPY":
            return "¥"
        case "KES":
            return "KSh"
        case "KGS":
            return "лв"
        case "KHR":
            return "៛"
        case "KRW":
            return "₩"
        case "KZT":
            return "лв"
        case "LAK":
            return "₭"
        case "LBP":
            return "£"
        case "LKR":
            return "₨"
        case "LRD":
            return "$"
        case "LTL":
            return "Lt"
        case "LVL":
            return "Ls"
        case "MKD":
            return "ден"
        case "MNT":
            return "₮"
        case "MUR":
            return "₨"
        case "MXN":
            return "$"
        case "MYR":
            return "RM"
        case "NAD":
            return "$"
        case "NGN":
            return "₦"
        case "NIO":
            return "C$"
        case "NOK":
            return "kr"
        case "NPR":
            return "₨"
        case "NZD":
            return "$"
        case "PEN":
            return "S/."
        case "PHP":
            return "Php"
        case "PKR":
            return "₨"
        case "PLN":
            return "zł"
        case "PYG":
            return "Gs"
        case "RON":
            return "lei"
        case "RSD":
            return "Дин"
        case "RUB":
            return "руб"
        case "SAR":
            return "﷼"
        case "SEK":
            return "kr"
        case "SGD":
            return "$"
        case "SYP":
            return "£"
        case "THB":
            return "฿"
        case "TRY":
            return "TL"
        case "TTD":
            return "TT$"
        case "TWD":
            return "NT$"
        case "UAH":
            return "₴"
        case "USD":
            return "$"
        case "UYU":
            return "$U"
        case "UZS":
            return "лв"
        case "VEF":
            return "Bs"
        case "VND":
            return "₫"
        case "XCD":
            return "$"
        case "YER":
            return "﷼"
        case "ZAR":
            return "R"
        default:
            return code
    }
}
