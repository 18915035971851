import { CJProcessingStateSelection } from "../domain/cjProcessingState"
import { ConsentStateSelection, DEFAULT_CONSENT_STATE_SELECTION } from "../domain/consentState"
import { ConversionListContextProps, Provider } from "./ConversionListContext"
import { ConversionListExternalDependencyContextProvider } from "domain/ConversionList/context/ConversionListExternalDependencyContextProvider"
import {
    ConversionStateSelection,
    DEFAULT_CONVERSION_STATE_SELECTION,
} from "domain/ConversionList/domain/conversionState"
import { ConversionTypeSelection, DEFAULT_CONVERSION_TYPE_SELECTION } from "domain/ConversionList/domain/conversionType"
import { CustomerJourneyConfig } from "domain/ConversionList/domain/customerJourneyConfig"
import {
    CONVERSION_LIST_TP_TS_VALUE_COLUMN_FIELD,
    TRANSACTION_TS_VALUE_COLUMN_FIELD,
} from "domain/ConversionList/domain/dimensionIdentifiers"
import {
    ConversionListType,
    QueryConfig,
    SearchConfig,
    TimeRange,
    TouchpointPropertyFilter,
    TouchpointPropertyFilterDialogMode,
    makeConversionListColumns,
} from "domain/ConversionList/domain/domain"
import {
    FullOrSoftConversionSelection,
    defaultFullOrSoftConversionSelection,
} from "domain/ConversionList/domain/fullOrSoftConversion"
import { DEFAULT_TRACKING_STATE_SELECTION, TrackingStateSelection } from "domain/ConversionList/domain/trackingState"
import { ConversionListOptionalSettingsDTOCjModeEnum, GridElementConfigDTO, SortSettingsDTO } from "generated/models"
import React, { type JSX, useMemo } from "react"
import { uniqueBy } from "remeda"
import { useChangeDetection } from "shared/hooks"
import { match } from "ts-pattern"
import { v4 as uuid } from "uuid"

export interface ConversionListContextProviderProps {
    type: ConversionListType
    initialTimeRange: TimeRange
    gridElementConfig: GridElementConfigDTO
    updateSelectedColumnIdentifiers: (
        selectedColumnIdentifiers: ReadonlyArray<string>,
        pinnedColumnIdentifiers: ReadonlyArray<string>,
    ) => void
    resetGridColumnState: () => void
    ConversionListExternalDependencyContextOverride?: typeof ConversionListExternalDependencyContextProvider
    children: React.ReactNode
}

export const ConversionListContextProvider = ({
    type,
    initialTimeRange,
    gridElementConfig,
    updateSelectedColumnIdentifiers,
    ConversionListExternalDependencyContextOverride,
    resetGridColumnState,
    children,
}: ConversionListContextProviderProps): JSX.Element => {
    // These columns are always visible and can not be disabled in the column configurator
    const ALLWAYS_VISIBLE_COLUMNS = [TRANSACTION_TS_VALUE_COLUMN_FIELD, CONVERSION_LIST_TP_TS_VALUE_COLUMN_FIELD]
    const columns = useMemo(() => {
        const result = makeConversionListColumns(gridElementConfig)

        // Filter out ALLWAYS_VISIBLE_COLUMNS so that they can not be disabled in the column configurator
        return {
            fieldNames: result.fieldNames.filter((fieldName) => !ALLWAYS_VISIBLE_COLUMNS.includes(fieldName)),
            columnDetails: Object.fromEntries(
                Object.entries(result.columnDetails).filter(([key]) => !ALLWAYS_VISIBLE_COLUMNS.includes(key)),
            ),
        }
    }, [gridElementConfig])
    const selectedColumns = gridElementConfig.gridConfig.visiblePerDefaultColumns.filter(
        (fieldName) => !ALLWAYS_VISIBLE_COLUMNS.includes(fieldName),
    )

    const leftPinnedColumns = gridElementConfig.gridConfig.supportedColumnConfigs
        .filter(
            (column) => column.gridColumnProperties.isFixed && selectedColumns.indexOf(column.columnIdentifier) >= 0,
        )
        .map((column) => column.columnIdentifier)

    const [sortSettings, setSortSettings] = React.useState<SortSettingsDTO>(
        gridElementConfig.gridConfig.defaultSortSettings,
    )
    const [timeRange, setTimeRange] = React.useState<TimeRange>(initialTimeRange)
    const [conversionTypeSelection, setConversionTypeSelection] = React.useState<ConversionTypeSelection>(
        DEFAULT_CONVERSION_TYPE_SELECTION,
    )
    const [fullOrSoftConversionSelection, setFullOrSoftConversionSelection] =
        React.useState<FullOrSoftConversionSelection>(defaultFullOrSoftConversionSelection(type))
    const [trackingStateSelection, setTrackingStateSelection] = React.useState<TrackingStateSelection>(
        DEFAULT_TRACKING_STATE_SELECTION,
    )
    const [customerJourneyConfig, setCustomerJourneyConfig] = React.useState<CustomerJourneyConfig>({
        cjMode: match(type)
            .with("historical", () => ConversionListOptionalSettingsDTOCjModeEnum.ALL)
            .with("realtime", () => ConversionListOptionalSettingsDTOCjModeEnum.ALL_WINNERS)
            .exhaustive(),
        showBlockedTouchpoints: false,
    })
    const [searchConfig, setSearchConfig] = React.useState<SearchConfig>(() => ({
        fieldName: undefined,
        searchTerm: undefined,
    }))
    const [propertyFilterDialogOpen, setPropertyFilterDialogOpen] = React.useState<boolean>(false)
    const [propertyFilterDialogMode, setPropertyFilterDialogMode] = React.useState<TouchpointPropertyFilterDialogMode>({
        type: "add",
    })
    const [propertyFilterDialogId, setPropertyFilterDialogId] = React.useState<string>(() => uuid())
    const [touchpointPropertyFilters, setTouchpointPropertyFilters] = React.useState<
        ReadonlyArray<TouchpointPropertyFilter>
    >([])
    const [conversionStateSelection, setConversionStateSelection] = React.useState<ConversionStateSelection>(
        DEFAULT_CONVERSION_STATE_SELECTION,
    )
    const [consentStateSelection, setConsentStateSelection] = React.useState<ConsentStateSelection>(
        DEFAULT_CONSENT_STATE_SELECTION,
    )
    const [cjProcessingStateSelection, setCJProcessingStateSelection] = React.useState<CJProcessingStateSelection>("")

    const [hasUnappliedChanges, resetHasUnappliedChanges] = useChangeDetection([
        timeRange,
        conversionTypeSelection,
        conversionStateSelection,
        consentStateSelection,
        cjProcessingStateSelection,
        fullOrSoftConversionSelection,
        trackingStateSelection,
        customerJourneyConfig,
        touchpointPropertyFilters,
        searchConfig,
    ])

    const [currentQueryConfig, setCurrentQueryConfig] = React.useState<QueryConfig>(() => {
        return {
            sortSettings,
            timeRange,
            conversionTypeSelection,
            fullOrSoftConversionSelection,
            trackingStateSelection,
            customerJourneyConfig,
            touchpointPropertyFilters,
            searchConfig,
            conversionStateSelection,
            consentStateSelection,
            cjProcessingStateSelection,
        }
    })

    const applyChanges = () => {
        setCurrentQueryConfig({
            sortSettings,
            timeRange,
            conversionTypeSelection,
            fullOrSoftConversionSelection,
            trackingStateSelection,
            customerJourneyConfig,
            touchpointPropertyFilters,
            searchConfig,
            conversionStateSelection,
            consentStateSelection,
            cjProcessingStateSelection,
        })
        resetHasUnappliedChanges()
    }

    const openPropertyFilterDialog = React.useCallback((mode: TouchpointPropertyFilterDialogMode) => {
        setPropertyFilterDialogMode(mode)
        setPropertyFilterDialogId(uuid())
        setPropertyFilterDialogOpen(true)
    }, [])

    const closePropertyFilterDialog = React.useCallback(() => {
        setPropertyFilterDialogOpen(false)
    }, [])

    const updateTouchpointPropertyFilters = React.useCallback((filters: ReadonlyArray<TouchpointPropertyFilter>) => {
        const filterMap = new Map<string, TouchpointPropertyFilter>()

        filters.forEach((filter) => {
            const key = `${filter.customerJourneyRole}:${filter.filterDimension}`
            const existing = filterMap.get(key)

            if (existing) {
                const uniqueValues = uniqueBy([...existing.values, ...filter.values], (value) => value.value)

                filterMap.set(key, {
                    id: existing.id,
                    customerJourneyRole: existing.customerJourneyRole,
                    filterDimension: existing.filterDimension,
                    values: uniqueValues,
                })
            } else {
                filterMap.set(key, filter)
            }
        })

        setTouchpointPropertyFilters(Array.from(filterMap.values()))
    }, [])

    const value: ConversionListContextProps = {
        dataManagerIdentifier: gridElementConfig.gridConfig.dataManagerIdentifier,
        currentQueryConfig,
        hasUnappliedChanges,
        applyChanges,
        type,
        columns,
        selectedColumns,
        updateSelectedColumns: updateSelectedColumnIdentifiers,
        leftPinnedColumns,
        sortSettings: sortSettings,
        setSortSettings: setSortSettings,
        timeRange,
        updateTimeRange: setTimeRange,
        conversionTypeSelection,
        updateConversionTypeSelection: setConversionTypeSelection,
        fullOrSoftConversionSelection,
        updateFullOrSoftConversionSelection: setFullOrSoftConversionSelection,
        trackingStateSelection,
        updateTrackingStateSelection: setTrackingStateSelection,
        customerJourneyConfig: customerJourneyConfig,
        updateCustomerJourneyConfig: setCustomerJourneyConfig,
        searchConfig,
        updateSearchConfig: setSearchConfig,
        propertyFilterDialogOpen,
        propertyFilterDialogMode,
        propertyFilterDialogId,
        openPropertyFilterDialog,
        closePropertyFilterDialog,
        touchpointPropertyFilters,
        updateTouchpointPropertyFilters: updateTouchpointPropertyFilters,
        resetGridColumnState: resetGridColumnState,
        conversionStateSelection: conversionStateSelection,
        updateConversionStateSelection: setConversionStateSelection,
        consentStateSelection: consentStateSelection,
        updateConsentStateSelection: setConsentStateSelection,
        cjProcessingStateSelection: cjProcessingStateSelection,
        updateCJProcessingStateSelection: setCJProcessingStateSelection,
    }

    const ExternalDependencyProvider =
        ConversionListExternalDependencyContextOverride ?? ConversionListExternalDependencyContextProvider
    return (
        <Provider value={value}>
            <ExternalDependencyProvider>{children}</ExternalDependencyProvider>
        </Provider>
    )
}
