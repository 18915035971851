import {
    CONVERSION_ICON_VALUE_COLUMN_FIELD,
    TRANSACTION_TS_VALUE_COLUMN_FIELD,
} from "domain/ConversionList/domain/dimensionIdentifiers"
import { ColumnConfigDTO, ColumnRendererDTOTypeEnum } from "generated/models"

export const conversionIconColumnConfig: ColumnConfigDTO = {
    columnIdentifier: CONVERSION_ICON_VALUE_COLUMN_FIELD,
    gridColumnProperties: {
        columnHeader: "",
        editable: false,
        isFixed: true,
        isMetric: false,
        nullValuesVisible: false,
        postfix: "",
        prefix: "",
        renderer: { type: ColumnRendererDTOTypeEnum.VALUE, cssClasses: new Set() },
        sortable: false,
        width: 40,
    },
}
export const transactionTsColumnConfig: ColumnConfigDTO = {
    columnIdentifier: TRANSACTION_TS_VALUE_COLUMN_FIELD,
    gridColumnProperties: {
        columnHeader: "Time of Conversion",
        renderer: {
            type: ColumnRendererDTOTypeEnum.DATE_TIME_SECONDS,
            cssClasses: new Set(),
        },
        editable: false,
        sortable: true,
        isMetric: false,
        isFixed: false,
        postfix: "",
    },
}
