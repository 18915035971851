import { Stack } from "@mui/material"
import { ColumnResponseDTO } from "generated/models"
import * as React from "react"

export const durationRenderer = (value: ColumnResponseDTO) => {
    if (!value) {
        return undefined
    }

    const valueNumber = Number(value)

    if (valueNumber > 0) {
        const days = Math.floor(valueNumber / 86400)
        const hours = Math.floor((valueNumber % 86400) / 3600)
        const minutes = Math.floor((valueNumber % 3600) / 60)
        const seconds = Math.floor(valueNumber % 60)
        return `${days > 0 ? days + "d " : ""}${hours > 0 ? hours + "h " : ""}${
            minutes > 0 ? minutes + "m " : ""
        }${seconds > 0 ? seconds + "s" : ""}`
    }

    return undefined
}
