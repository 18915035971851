import { CJProcessingStateSelection } from "domain/ConversionList/domain/cjProcessingState"
import { ConsentStateSelection } from "domain/ConversionList/domain/consentState"
import { ConversionStateSelection } from "domain/ConversionList/domain/conversionState"
import { ConversionTypeSelection } from "domain/ConversionList/domain/conversionType"
import { CustomerJourneyConfig } from "domain/ConversionList/domain/customerJourneyConfig"
import { FullOrSoftConversionSelection } from "domain/ConversionList/domain/fullOrSoftConversion"
import { TouchpointCustomerJourneyRole } from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import { TouchpointFilterDimension } from "domain/ConversionList/domain/touchpointFilterDimension"
import { TrackingStateSelection } from "domain/ConversionList/domain/trackingState"
import { getVisiblePerDefaultColumnConfigs } from "domain/legacy/widget/generic/GridUtil"
import { ColumnConfigDTO, GridElementConfigDTO, SortSettingsDTO } from "generated/models"
import { produce } from "immer"

export type ConversionListType = "historical" | "realtime"

export type TimeRange = Readonly<[start: moment.Moment | null, end: moment.Moment | null]>

export type DimensionValue = Readonly<{
    value: number
    name: string
}>

export type TouchpointPropertyFilter = Readonly<{
    id: string
    customerJourneyRole: TouchpointCustomerJourneyRole
    filterDimension: TouchpointFilterDimension
    values: DimensionValue[]
}>

export type PaginationSettings = Readonly<{
    pageSize: number
    page: number
}>

export type QueryConfig = Readonly<{
    sortSettings: SortSettingsDTO
    timeRange: TimeRange
    conversionTypeSelection: ConversionTypeSelection
    fullOrSoftConversionSelection: FullOrSoftConversionSelection
    trackingStateSelection: TrackingStateSelection
    customerJourneyConfig: CustomerJourneyConfig
    touchpointPropertyFilters: ReadonlyArray<TouchpointPropertyFilter>
    searchConfig: SearchConfig
    conversionStateSelection: ConversionStateSelection
    consentStateSelection: ConsentStateSelection
    cjProcessingStateSelection: CJProcessingStateSelection
}>

export type TouchpointPropertyFilterDialogMode = Readonly<
    | {
          type: "add"
      }
    | {
          type: "edit"
          id: string
      }
>

export type SearchConfig = Readonly<{
    fieldName?: string
    searchTerm?: string
}>

export type ColumnCategory = "conversion" | "winningTouchpoint" | "customParameter"
export type ColumnCategoryDetails = Readonly<{
    sortOrder: number
    displayNamePlural: string
}>
export const columnCategories: Record<ColumnCategory, ColumnCategoryDetails> = {
    conversion: {
        sortOrder: 0,
        displayNamePlural: "Conversions",
    },
    winningTouchpoint: {
        sortOrder: 1,
        displayNamePlural: "Winning Touchpoints",
    },
    customParameter: {
        sortOrder: 2,
        displayNamePlural: "Custom Parameters",
    },
} as const

const WINNING_TOUCHPOINT_PREFIX = "TP: "
const CUSTOM_PARAMETER_PREFIX = "Custom: "

const shortenDisplayName = (displayName: string): [columnCategory: ColumnCategory, shortDisplayName: string] => {
    if (displayName.startsWith(WINNING_TOUCHPOINT_PREFIX)) {
        return ["winningTouchpoint", displayName.replace(WINNING_TOUCHPOINT_PREFIX, "")]
    }
    if (displayName.startsWith(CUSTOM_PARAMETER_PREFIX)) {
        return ["customParameter", displayName.replace(CUSTOM_PARAMETER_PREFIX, "")]
    }
    return ["conversion", displayName]
}

export type ConversionListColumnDetails = Readonly<{
    fieldName: string
    columnCategory: ColumnCategory
    supportsSearch: boolean
    columnConfigDTO: ColumnConfigDTO
}>

export type ConversionListColumns = Readonly<{
    fieldNames: ReadonlyArray<string>
    columnDetails: Record<string, ConversionListColumnDetails>
}>

export const makeConversionListColumns = (gridElementConfig: GridElementConfigDTO): ConversionListColumns => {
    const fieldNames = Array.from(gridElementConfig.gridConfig.supportedColumnConfigs).map(
        (column) => column.columnIdentifier,
    )
    const supportedSearchColumns = new Set(gridElementConfig.gridConfig.supportedSearchColumns)

    const columnDetails = Array.from(gridElementConfig.gridConfig.supportedColumnConfigs).reduce(
        (acc, column) => {
            const [columnCategory, shortDisplayName] = shortenDisplayName(column.gridColumnProperties.columnHeader)
            return {
                ...acc,
                [column.columnIdentifier]: {
                    fieldName: column.columnIdentifier,
                    columnCategory: columnCategory,
                    supportsSearch: supportedSearchColumns.has(column.columnIdentifier),
                    columnConfigDTO: produce(column, (draft) => {
                        draft.gridColumnProperties.columnHeader = shortDisplayName
                    }),
                },
            }
        },
        {} as Record<string, ConversionListColumnDetails>,
    )

    return { fieldNames, columnDetails }
}

// Almost the same as ConversionListColumns, maybe this should be refactored
export type CustomerJourneyColumnDetails = Readonly<{
    fieldName: string
    columnConfigDTO: ColumnConfigDTO
}>

export type CustomerJourneyColumns = Readonly<{
    fieldNames: ReadonlyArray<string>
    columnDetails: Record<string, CustomerJourneyColumnDetails>
}>

export const makeCustomerJourneyColumns = (gridElementConfig: GridElementConfigDTO): CustomerJourneyColumns => {
    const fieldNames = gridElementConfig.gridConfig.visiblePerDefaultColumns

    const columnDetails = getVisiblePerDefaultColumnConfigs(gridElementConfig.gridConfig).reduce(
        (acc, column) => {
            return {
                ...acc,
                [column.columnIdentifier]: {
                    fieldName: column.columnIdentifier,
                    columnConfigDTO: column,
                },
            }
        },
        {} as Record<string, CustomerJourneyColumnDetails>,
    )

    return { fieldNames, columnDetails }
}
