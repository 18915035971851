import { Popover, Typography } from "@mui/material"
import { ShowPixelsDialog } from "domain/ConversionList/components/ShowPixels/ShowPixelsDialog"
import moment from "moment/moment"
import React, { useEffect } from "react"

type PixelOrMessageProps = {
    anchorEl: HTMLElement | null
    shown: boolean
    onCloseDialog: () => void
    campaignId: number
    transactionUid: string
    timestamp: string
}

const PIXEL_RETENTION_DAYS = 60

export const PixelOrMessage = (props: PixelOrMessageProps) => {
    const { timestamp, anchorEl } = props
    const [internalAnchorEl, setInternalAnchorEl] = React.useState(anchorEl)

    useEffect(() => {
        setInternalAnchorEl(anchorEl)
    }, [anchorEl])

    const retentionDate = moment().subtract(PIXEL_RETENTION_DAYS, "days")
    const date = moment(timestamp)

    if (date.isBefore(retentionDate)) {
        const handleClose = () => {
            setInternalAnchorEl(null)
        }
        const open = Boolean(internalAnchorEl)

        return (
            <Popover
                open={open}
                anchorEl={internalAnchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={handleClose}
            >
                <Typography sx={{ p: 1 }}>Conversion pixel data is available for the past 60 days only.</Typography>
            </Popover>
        )
    }

    return <ShowPixelsDialog {...props} />
}
