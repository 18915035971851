import { TextField } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { DimensionValue } from "domain/ConversionList/domain/domain"
import {
    TOUCHPOINT_FILTER_DIMENSIONS,
    TouchpointFilterDimension,
} from "domain/ConversionList/domain/touchpointFilterDimension"
import React from "react"
import { VirtualizedAutocomplete } from "shared/component/mui/VirtualizedAutocomplete"

export interface TouchpointDimensionFilterValueAutocompleteProps {
    selectedTouchpointFilterDimension: TouchpointFilterDimension
    selectedTouchpointFilterDimensionValues: DimensionValue[]
    updateSelectedTouchpointFilterDimensionValues: (dimensionValues: DimensionValue[]) => void
}

export const TouchpointDimensionFilterValueAutocomplete = ({
    selectedTouchpointFilterDimension,
    selectedTouchpointFilterDimensionValues,
    updateSelectedTouchpointFilterDimensionValues,
}: TouchpointDimensionFilterValueAutocompleteProps) => {
    const values = ConversionListContextSelectors.useDimensionValuesQuery({
        touchpointFilterDimension: selectedTouchpointFilterDimension,
    })

    // TODO: Handle values.isError state

    return (
        <VirtualizedAutocomplete
            multiple={true}
            loading={values.isPending}
            options={values.data || []}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableCloseOnSelect={true}
            renderOption={(props, option) => (
                <li
                    {...props}
                    style={{
                        wordBreak: "break-all",
                        whiteSpace: "normal",
                        padding: "8px 16px",
                    }}
                >
                    {option.name}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={
                        selectedTouchpointFilterDimensionValues.length === 0
                            ? `Select ${TOUCHPOINT_FILTER_DIMENSIONS[selectedTouchpointFilterDimension].displayName.toLowerCase()}...`
                            : ""
                    }
                    sx={{ width: "100%" }}
                    InputLabelProps={{ shrink: false }}
                />
            )}
            sx={{
                minWidth: {
                    xs: "240px", // On smallest screens
                    sm: "260px", // On small screens
                },
                width: "100%",
                maxWidth: {
                    xs: "100%", // Full width on small screens
                    sm: "100%", // Full width on small screens
                    md: "500px", // Limited width only on medium+ screens (when not wrapped)
                },
                flexGrow: 1,
                alignSelf: "center",
                "& .MuiAutocomplete-tag": {
                    maxWidth: "100%",
                    overflow: "hidden",
                    margin: "3px",
                    padding: "5px 4px 5px 6px",
                    backgroundColor: "white",
                    borderRadius: "16px",
                    height: "auto",
                    minHeight: "24px",
                    maxHeight: "59px",
                    fontSize: "12px",
                    wordBreak: "break-all",
                    boxShadow: `0px 0px 0px 1px rgb(0 0 0 / 0.04),
                      0px 1px 1px -0.5px rgb(0 0 0 / 0.03),
                      0px 2px 2px -1px rgb(0 0 0 / 0.03),
                      0px 4px 4px -2px rgb(0 0 0 / 0.02)`,
                },
                "& .MuiChip-root": {
                    display: "flex",
                    alignItems: "flex-start", // Align items to top
                    height: "auto",
                    maxHeight: "59px",
                },
                "& .MuiChip-deleteIcon": {
                    fontSize: "14px", // Smaller icon
                    margin: "1px 0 0 4px", // Adjusted top margin to align with text
                    padding: 0,
                    alignSelf: "flex-start", // Explicitly align to top
                },
                "& .MuiChip-label": {
                    padding: "0 0 0 2px", // Reduced left padding
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                    lineHeight: "16px",
                    height: "auto",
                    maxHeight: "50px",
                    wordBreak: "break-all",
                    display: "block",
                    maxWidth: "calc(100% - 14px)",
                    fontSize: "12px",
                },
                "& .MuiOutlinedInput-root": {
                    flexWrap: "wrap",
                    padding: "8px 4px",
                },
                "& .MuiAutocomplete-inputRoot": {
                    flexWrap: "wrap",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "4px",
                },
                "& .MuiAutocomplete-listbox": {
                    maxWidth: "95vw",
                    "& li": {
                        wordBreak: "break-all",
                    },
                },
                "& .MuiAutocomplete-popper": {
                    width: "auto !important",
                    minWidth: "240px",
                    "& .MuiPaper-root": {
                        width: "auto !important",
                        minWidth: "240px",
                        maxWidth: "95vw",
                    },
                },
            }}
            value={selectedTouchpointFilterDimensionValues}
            onChange={(_, value) => updateSelectedTouchpointFilterDimensionValues(value)}
        />
    )
}
