import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box, Stack, Typography } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { CJProcessingStateFilter } from "domain/ConversionList/components/ConfigPanel/CJProcessingStateFilter"
import { ConsentStateFilter } from "domain/ConversionList/components/ConfigPanel/ConsentStateFilter"
import { CustomerJourneyConfigForm } from "domain/ConversionList/components/ConfigPanel/ConversionDisplayOptions/CustomerJourneyConfigForm"
import { ConversionTypeRadioButtonGroup } from "domain/ConversionList/components/ConfigPanel/ConversionProperties/ConversionTypeRadioButtonGroup"
import { FullOrSoftConversionRadioButtonGroup } from "domain/ConversionList/components/ConfigPanel/ConversionProperties/FullOrSoftConversionRadioButtonGroup"
import { TrackingStateRadioButtonGroup } from "domain/ConversionList/components/ConfigPanel/ConversionProperties/TrackingStateRadioButtonGroup"
import { ConversionStateFilter } from "domain/ConversionList/components/ConfigPanel/ConversionStateFilter"
import { Search } from "domain/ConversionList/components/ConfigPanel/Search/Search"
import { TimeSpanSelection } from "domain/ConversionList/components/ConfigPanel/TimeSpanSelection"
import { AddPropertyFilterButton } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/AddPropertyFilterButton"
import { CurrentPropertyFiltersList } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/CurrentPropertyFiltersList"
import { PropertyFilterDialog } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/PropertyFilterDialog"
import { UpdateButton } from "domain/ConversionList/components/ConfigPanel/UpdateButton"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { CONFIG_PANEL_WIDTH } from "domain/ConversionList/domain/constants"
import moment, { Moment } from "moment"
import React, { useEffect, useId, useRef, useState } from "react"
import { FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

export const ConfigPanel = () => {
    const propertyFilterDialogId = ConversionListContextSelectors.usePropertyFilterDialogId()
    const journeyGranularitySectionId = useId()
    const searchAndFiltersSectionId = useId()
    const journeyFiltersSectionId = useId()

    const [isExpandedJourneyGranularity, setIsExpandedJourneyGranularity] = React.useState(false)
    const [isExpandedSearchAndFilters, setIsExpandedSearchAndFilters] = React.useState(false)
    const [isExpandedJourneyFilters, setIsExpandedJourneyFilters] = React.useState(false)

    // For button stickiness
    const [isButtonSticky, setIsButtonSticky] = useState(false)
    const contentRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLDivElement>(null)

    // Observer to handle button stickiness based on scroll position
    useEffect(() => {
        if (!contentRef.current || !buttonRef.current) return

        const options = {
            root: contentRef.current,
            rootMargin: "0px",
            threshold: 0,
        }

        const observer = new IntersectionObserver((entries) => {
            if (entries[0]) {
                setIsButtonSticky(!entries[0].isIntersecting)
            }
        }, options)

        observer.observe(buttonRef.current)

        return () => observer.disconnect()
    }, [])

    const type = ConversionListContextSelectors.useConversionListType()

    const currentMinMaxTimeRange = (): [Moment, Moment] => {
        const now = moment()

        switch (type) {
            case "realtime": {
                const threeDaysAgo = now.clone().subtract(3, "day").startOf("day")
                const todayAtEndOfDay = now.clone().endOf("day")
                return [threeDaysAgo, todayAtEndOfDay]
            }
            case "historical": {
                const threeYearsAgoAtStartOfDay = now.clone().subtract(3, "year").startOf("day")
                const oneDayAgoAtEndOfDay = now.clone().subtract(1, "day").endOf("day")
                return [threeYearsAgoAtStartOfDay, oneDayAgoAtEndOfDay]
            }
        }
    }

    return (
        <Box
            className="config-panel"
            sx={{
                width: CONFIG_PANEL_WIDTH,
                borderLeft: "1px solid #e0e0e0",
                height: "100%",
                position: "relative",
                backgroundColor: "#FAFBFF",
                display: "flex",
                flexDirection: "column",
                "& .MuiAccordionSummary-content": {
                    margin: 0,
                    padding: 0,
                    width: "100%",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: 0,
                },
                "& .MuiAccordion-root.Mui-expanded": {
                    margin: 0,
                },
                "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "auto",
                    mb: "8px",
                    width: "100%",
                },
                "& .MuiAccordionDetails-root": {
                    paddingTop: 0,
                },
                "& .MuiAccordion-root": {
                    backgroundColor: "transparent !important",
                    width: "100%",
                },
                "& .MuiAccordionSummary-expandIconWrapper": {
                    marginLeft: "auto",
                    marginRight: "0px",
                    "& svg": {
                        width: "20px",
                        height: "20px",
                    },
                },
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)", // Rotate to down when expanded
                },
            }}
        >
            <Box
                className="config-panel-content"
                ref={contentRef}
                sx={{
                    overflowY: "auto",
                    height: "100%",
                    px: 2,
                    pb: 2,
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                {/*<InfoText />*/}
                <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }} className={"time-span"}>
                        <Stack direction="column" spacing={0.75} sx={{ width: "100%" }}>
                            <TimeSpanSelection
                                minDateTime={currentMinMaxTimeRange()[0]}
                                maxDateTime={currentMinMaxTimeRange()[1]}
                                maxDuration={31}
                            />
                        </Stack>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 0.5, mt: 2 }}>
                    <TrackingStateRadioButtonGroup />
                    <ConversionTypeRadioButtonGroup />
                    <FullOrSoftConversionRadioButtonGroup />
                </Box>

                <Stack
                    direction="column"
                    spacing={0}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        mt: 1.5,
                        flex: 1,
                    }}
                >
                    <Accordion
                        elevation={0}
                        sx={{
                            "&:before": { height: "0px" },
                            paddingTop: "8px",
                            width: "100%",
                            backgroundColor: "transparent",
                        }}
                        expanded={isExpandedSearchAndFilters}
                        onChange={(_, expanded) => setIsExpandedSearchAndFilters(expanded)}
                    >
                        <AccordionSummary
                            expandIcon={<ChevronRightIcon />}
                            aria-controls={`${searchAndFiltersSectionId}-content`}
                            id={searchAndFiltersSectionId}
                            sx={{
                                width: "100%",
                                justifyContent: "space-between",
                                padding: "0",
                            }}
                        >
                            <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD }}>Search & filters</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: "flex", alignItems: "center", padding: "0 0 12px 0" }}>
                            <Box sx={{ width: "100%", mb: 1 }}>
                                <Box sx={{ mb: 0.2, "& .MuiSelect-root": { height: "32px", lineHeight: "32px" } }}>
                                    <ConversionStateFilter />
                                </Box>
                                <Box sx={{ mb: 0.2, "& .MuiSelect-root": { height: "32px", lineHeight: "32px" } }}>
                                    <ConsentStateFilter />
                                </Box>
                                <Box sx={{ mb: 1, "& .MuiSelect-root": { height: "32px", lineHeight: "32px" } }}>
                                    <CJProcessingStateFilter />
                                </Box>
                                <Box
                                    sx={{
                                        mb: 0,
                                        mt: 1,
                                        "& .MuiInputBase-root": { height: "32px" },
                                        "& .MuiAutocomplete-root": {
                                            paddingBottom: 0,
                                            marginBottom: "5px",
                                        },
                                        "& .MuiAutocomplete-root .MuiAutocomplete-endAdornment": {
                                            marginBottom: 0,
                                        },
                                    }}
                                >
                                    <Search />
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        elevation={0}
                        sx={{
                            "&:before": { height: "0px" },
                            paddingTop: "8px",
                            width: "100%",
                            backgroundColor: "transparent",
                        }}
                        expanded={isExpandedJourneyFilters}
                        onChange={(_, expanded) => setIsExpandedJourneyFilters(expanded)}
                    >
                        <AccordionSummary
                            expandIcon={<ChevronRightIcon />}
                            aria-controls={`${journeyFiltersSectionId}-content`}
                            id={journeyFiltersSectionId}
                            sx={{
                                width: "100%",
                                justifyContent: "space-between",
                                padding: "0",
                            }}
                        >
                            <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD }}>Journey filters</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ display: "flex", alignItems: "center", padding: "0 0 12px 0" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 0.5 }}>
                                <CurrentPropertyFiltersList />
                                <AddPropertyFilterButton />
                                <PropertyFilterDialog key={propertyFilterDialogId} />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        elevation={0}
                        sx={{
                            "&:before": { height: "0px" },
                            paddingTop: "8px",
                            width: "100%",
                            backgroundColor: "transparent",
                        }}
                        expanded={isExpandedJourneyGranularity}
                        onChange={(_, expanded) => setIsExpandedJourneyGranularity(expanded)}
                    >
                        <AccordionSummary
                            expandIcon={<ChevronRightIcon />}
                            aria-controls={`${journeyGranularitySectionId}-content`}
                            id={journeyGranularitySectionId}
                            sx={{
                                width: "100%",
                                justifyContent: "space-between",
                                padding: "0",
                            }}
                        >
                            <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD }}>Journey granularity</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                                padding: "0 0 12px 0",
                            }}
                        >
                            <CustomerJourneyConfigForm />
                        </AccordionDetails>
                    </Accordion>

                    {/* Button container that's monitored for visibility */}
                    <Box
                        ref={buttonRef}
                        sx={{
                            width: "100%",
                            mt: 1.5,
                            mb: 1,
                        }}
                    >
                        <UpdateButton />
                    </Box>
                </Stack>
            </Box>

            {/* Sticky button that appears when the normal button scrolls out of view */}
            {isButtonSticky && (
                <Box
                    className="sticky-button-container"
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        padding: "16px",
                        backgroundColor: "#FAFBFF",
                        borderTop: "1px solid",
                        borderColor: "divider",
                        zIndex: 10,
                    }}
                >
                    <UpdateButton />
                </Box>
            )}
        </Box>
    )
}
