import ConversionSvg from "./images/conversion.svg?react"
import { Box, PopoverPosition } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GridAlignment,
    GridCallbackDetails,
    GridColDef,
    GridColumnHeaderParams,
    GridColumnOrderChangeParams,
    GridPinnedColumnFields,
    GridRowId,
    GridRowParams,
} from "@mui/x-data-grid-pro"
import type { GridSortModel } from "@mui/x-data-grid-pro"
import { ColumnHeader } from "domain/ConversionList/components/GridPanel/ColumnHeader"
import { GridControls } from "domain/ConversionList/components/GridPanel/GridControls"
import { CustomerJourneyPanel } from "domain/ConversionList/components/GridPanel/customerjourney/CustomerJourneyPanel"
import { PixelOrMessage } from "domain/ConversionList/components/ShowPixels/PixelOrMessage"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import {
    conversionIconColumnConfig,
    transactionTsColumnConfig,
} from "domain/ConversionList/domain/columnConfigurations"
import { calculateGridPanelWidth } from "domain/ConversionList/domain/constants"
import {
    CAMPAIGN,
    CONVERSION_ICON_VALUE_COLUMN_FIELD,
    TRANSACTION_TS,
    TRANSACTION_UID,
} from "domain/ConversionList/domain/dimensionIdentifiers"
import { ColumnField } from "domain/dimension/service/DimensionService"
import { ColumnConfigDTO, ColumnRendererDTOTypeEnum, ColumnResponseDTO } from "generated/models"
import { SortSettingsDTO } from "generated/models"
import { produce } from "immer"
import { useDrawerContext } from "layout/MainLayout/Drawer/DrawerContext"
import React, { useMemo, useState } from "react"
import { CustomizedDataGrid } from "shared/component/mui/datagrid/CustomizedDataGrid"
import { columnRenderer } from "shared/component/renderers/renderers"

const DEFAULT_SORT_SETTINGS: Readonly<SortSettingsDTO> = {
    sortProperties: [`${TRANSACTION_TS}.value`],
    sortAscending: false,
}

export const GridPanel = () => {
    const { isDrawerOpen } = useDrawerContext()
    const [showPixelAnchorEl, setShowPixelAnchorEl] = React.useState<HTMLElement | null>(null)

    const [paginationSettings, setPaginationSettings] = React.useState({
        page: 0,
        pageSize: 50,
    })

    const [openedRows, setOpenedRows] = React.useState<GridRowId[]>([])

    const type = ConversionListContextSelectors.useConversionListType()
    const queryConfig = ConversionListContextSelectors.useCurrentQueryConfig()

    // Right now, the realtime conversion list uses a separate /pagination endpoint, while the historical conversion list
    // returns pagination information in the loadData reponse.
    const usesSeparatePaginationQuery = type === "realtime"

    const sortSettings = ConversionListContextSelectors.useSortSettings()
    const setSortSettings = ConversionListContextSelectors.useSetSortSettings()

    const columns = ConversionListContextSelectors.useColumns()
    const selectedColumns = ConversionListContextSelectors.useSelectedColumns()
    const updateSelectedColumns = ConversionListContextSelectors.useUpdateSelectedColumns()
    const columnConfigs = useMemo(
        () => selectedColumns.map((fieldName) => columns.columnDetails[fieldName]!.columnConfigDTO),
        [columns, selectedColumns],
    )

    const leftPinnedColumns = ConversionListContextSelectors.useLeftPinnedColumns()

    const queryResult = ConversionListContextSelectors.useLoadDataQuery({
        queryConfig,
        paginationSettings,
        sortSettings,
    })

    const paginationQueryResult = ConversionListContextSelectors.useLoadPaginationQuery({
        enabled: usesSeparatePaginationQuery,
        queryConfig,
        paginationSettings,
        sortSettings,
    })

    const paginationQueryResultProp = (() => {
        if (!usesSeparatePaginationQuery) {
            return undefined
        }
        if (paginationQueryResult.isSuccess) {
            return {
                ...paginationQueryResult,
                data: {
                    pageSize: paginationQueryResult.data.pageSize,
                    pages: paginationQueryResult.data.pages,
                    totalEntities: paginationQueryResult.data.totalEntities,
                    page: paginationSettings.page,
                },
            }
        }
        return paginationQueryResult
    })()

    const handleSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            if (sortModel.length === 0 || sortModel[0] === undefined) {
                setSortSettings(DEFAULT_SORT_SETTINGS)
            } else {
                setSortSettings({
                    sortProperties: [sortModel[0].field],
                    sortAscending: sortModel[0].sort === "asc",
                })
            }
        },
        [setSortSettings],
    )

    const [shownDialog, setShownDialog] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState<{
        transactionUid: string
        timestamp: string
        campaignId: number
    } | null>(null)
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number
        mouseY: number
    } | null>(null)

    const handleContextMenu = React.useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault()
            const rowId = Number(event.currentTarget.getAttribute("data-id"))
            const rowData = queryResult.data?.dataSet.rows[rowId] as Record<string, any>

            setSelectedRowData({
                transactionUid: rowData[TRANSACTION_UID].value,
                timestamp: rowData[TRANSACTION_TS].value,
                campaignId: rowData[CAMPAIGN].value,
            })

            setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null)
        },
        [contextMenu, queryResult.data?.dataSet.rows],
    )

    const handleClose = () => {
        setContextMenu(null)
    }

    const handlePixelDialogClose = () => {
        setShownDialog(!shownDialog)
        setContextMenu(null)
    }

    const handlePixelDialogClick = (event: React.MouseEvent<HTMLElement>) => {
        setShowPixelAnchorEl(event.currentTarget)

        setContextMenu(null)
        setShownDialog(true)
    }

    const customerJourneyRenderer = React.useCallback(
        ({ row }: GridRowParams) => {
            const transactionUid = (row as Record<string, ColumnResponseDTO>)[
                ColumnField.valueField(TRANSACTION_UID).toString()
            ]
            return (
                <Box
                    sx={{
                        py: 1, // Slightly increased vertical padding
                        pl: 0, // Remove left padding to allow our custom alignment in the CustomerJourneyPanel
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.02)" : "rgba(255, 255, 255, 0.02)",
                        height: "auto", // Allow height to adjust to content
                        overflow: "visible", // Prevent content from being cut off
                    }}
                >
                    <CustomerJourneyPanel
                        transactionUid={transactionUid?.value}
                        onContextMenu={type == "historical" ? handleContextMenu : undefined}
                    />
                </Box>
            )
        },
        [handleContextMenu, type],
    )

    // These columns are always shown in the front of the grid rows
    const frontColumns = [conversionIconColumnConfig, transactionTsColumnConfig]
    const frontColumnIdentifiers = frontColumns.map((column) => column.columnIdentifier)

    const makeGridColDef = (dataColumn: ColumnConfigDTO): GridColDef => ({
        field: dataColumn.columnIdentifier,
        width: dataColumn.gridColumnProperties.width || 150,
        sortable: dataColumn.gridColumnProperties.sortable,
        // front columns are always shown in the front of the grid rows
        disableReorder: frontColumnIdentifiers.includes(dataColumn.columnIdentifier),
        disableColumnMenu: dataColumn.columnIdentifier === CONVERSION_ICON_VALUE_COLUMN_FIELD,
        pinnable: !frontColumnIdentifiers.includes(dataColumn.columnIdentifier),
        renderHeader: (_: GridColumnHeaderParams) =>
            dataColumn.columnIdentifier === CONVERSION_ICON_VALUE_COLUMN_FIELD ? null : (
                <ColumnHeader
                    label={dataColumn.gridColumnProperties.columnHeader}
                    columnCategory={columns.columnDetails[dataColumn.columnIdentifier]?.columnCategory}
                />
            ),
        renderCell: (params) =>
            dataColumn.columnIdentifier === CONVERSION_ICON_VALUE_COLUMN_FIELD ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <ConversionSvg style={{ width: "16px", height: "16px" }} />
                </Box>
            ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {dataColumn.gridColumnProperties.renderer ? columnRenderer(dataColumn)(params) : params.value}
                </Box>
            ),
        // Align right for metrics, left for dimensions
        align: getCellAlignment(dataColumn),
    })

    /**
     * Get the cell alignment based on the column configuration.
     *
     * @param columnConfig
     */
    const getCellAlignment = (columnConfig: ColumnConfigDTO): GridAlignment => {
        if (columnConfig.columnIdentifier === CONVERSION_ICON_VALUE_COLUMN_FIELD) return "center"
        if (columnConfig.gridColumnProperties.renderer.type == ColumnRendererDTOTypeEnum.ICON) return "center"
        if (columnConfig.gridColumnProperties.isMetric) return "right"

        return "left"
    }

    const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
        const newList = produce(selectedColumns, (draft) => {
            draft.splice(params.oldIndex - 1 - 2, 1)
            draft.splice(params.targetIndex - 1 - 2, 0, params.column.field)
        })
        updateSelectedColumns(
            newList,
            leftPinnedColumns.filter((column) => newList.indexOf(column) !== -1),
        )
    }

    const pinnedColumns: GridPinnedColumnFields = {
        left:
            leftPinnedColumns.length > 0
                ? // if there are pinned columns, we need to add the detail panel toggle column and the front columns to the left pinned columns
                  // because the detail panel toggle column and the front columns are not part of the column configs
                  [GRID_DETAIL_PANEL_TOGGLE_FIELD, ...frontColumnIdentifiers, ...leftPinnedColumns]
                : [],
    }

    const handlePinnedColumnsChange = (pinnedColumns: GridPinnedColumnFields) => {
        updateSelectedColumns(
            selectedColumns,
            pinnedColumns.left?.filter((column) => column !== GRID_DETAIL_PANEL_TOGGLE_FIELD) ?? [],
        )
    }

    const visibleColumns = [
        ...frontColumns,
        ...columnConfigs.filter((column) => !frontColumnIdentifiers.includes(column.columnIdentifier)),
    ]

    return (
        <Box
            className="grid-panel"
            sx={{
                width: calculateGridPanelWidth(isDrawerOpen),
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "hidden", // Contain any potential overflow
            }}
        >
            <GridControls />

            <CustomizedDataGrid
                visibleColumns={visibleColumns}
                queryResult={queryResult}
                onPageChange={(page, pageSize) => {
                    const newPage = paginationSettings.pageSize === pageSize ? page : 0
                    setPaginationSettings({ page: newPage, pageSize: pageSize })
                    // Reset opened rows when changing page
                    setOpenedRows([])
                }}
                muiDataGridProps={{
                    // Referenced function must have a stable identity
                    getDetailPanelHeight: autoPanelHeight,
                    // Referenced function must have a stable identity
                    getDetailPanelContent: customerJourneyRenderer,
                    onDetailPanelExpandedRowIdsChange: (ids: GridRowId[], _: GridCallbackDetails) => {
                        setOpenedRows(ids)
                    },
                    detailPanelExpandedRowIds: openedRows,

                    sortingMode: "server",
                    sortingOrder: ["asc", "desc"],
                    onSortModelChange: handleSortModelChange,
                    disableColumnSelector: true,
                    onColumnOrderChange: handleColumnOrderChange,
                    pinnedColumns: pinnedColumns,
                    onPinnedColumnsChange: handlePinnedColumnsChange,
                    initialState: {
                        sorting: {
                            sortModel: sortSettings.sortProperties?.map((field) => {
                                return { field: field, sort: sortSettings.sortAscending ? "asc" : "desc" }
                            }),
                        },
                    },
                    sx: {
                        minHeight: "400px",
                        "& .MuiDataGrid-detailPanel": {
                            overflow: "visible",
                        },
                    },
                }}
                onContextMenu={type == "historical" ? handleContextMenu : undefined}
                makeGridColDef={makeGridColDef}
                pinning="left"
                paginationQueryResult={paginationQueryResultProp}
            />
            {queryResult.isSuccess && type == "historical" && (
                <Menu
                    className="context-menu"
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? ({ top: contextMenu.mouseY, left: contextMenu.mouseX } as PopoverPosition)
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault()
                                if (!shownDialog) {
                                    handleClose()
                                }
                            },
                        },
                    }}
                >
                    <MenuItem onClick={handlePixelDialogClick}>Show Pixels</MenuItem>
                </Menu>
            )}
            {shownDialog && selectedRowData && (
                <PixelOrMessage
                    anchorEl={showPixelAnchorEl}
                    shown={shownDialog}
                    onCloseDialog={handlePixelDialogClose}
                    transactionUid={selectedRowData.transactionUid}
                    timestamp={selectedRowData.timestamp}
                    campaignId={selectedRowData.campaignId}
                />
            )}
        </Box>
    )
}

// Allow the panel to automatically adjust its height to fit the content
const autoPanelHeight = () => "auto" as const
