// Dimension identifiers, TODO: where to put these?
import { ColumnField } from "domain/dimension/service/DimensionService"

export const CAMPAIGN = "campaign"
export const CHANNEL = "channel"
export const LINE_ITEM = "line_item"
export const PUBLISHER = "publisher"
export const SUB_CAMPAIGN = "sub_campaign"
export const DIMENSION_CONVERSION_TYPE = "conversion_type_id"
export const IS_WINNER = "is_winner"
export const IS_INTRODUCER = "is_introducer"
export const IS_CLOSER = "is_closer"
export const IS_INFLUENCER = "is_influencer"
export const IS_INVOLVED = "is_involved"
export const IS_DIRECT_CONVERSION = "is_direct_conversion_id"
export const IS_TRACKED_CONVERSION = "is_tracked_conversion_id"
export const IS_SOFT_CONVERSION = "is_soft_conversion"
export const IS_FULL_CONVERSION = "is_full_conversion"
export const TRANSACTION_UID = "transaction_uid"
export const TRANSACTION_TS = "transaction_ts"
export const CONVERSION_LIST_TP_TTC = "conversion_list_tp_ttc"
export const CONVERSION_LIST_TP_TS = "conversion_list_tp_ts"
export const CONVERSION_STATE = "conversion_state_id"
export const CONSENT_STATUS = "is_opt_out_conversion"
export const CONVERSION_LIST_TP_TYPE = "conversion_list_tp_type"
export const CONVERSION_ICON_COLUMN_IDENTIFIER = "conversion_icon"
// conversion_icon.value
export const CONVERSION_ICON_VALUE_COLUMN_FIELD = ColumnField.valueField(CONVERSION_ICON_COLUMN_IDENTIFIER).toString()
// transaction_ts.value
export const TRANSACTION_TS_VALUE_COLUMN_FIELD = ColumnField.valueField(TRANSACTION_TS).toString()
// conversion_list_tp_ts.value
export const CONVERSION_LIST_TP_TS_VALUE_COLUMN_FIELD = ColumnField.valueField(CONVERSION_LIST_TP_TS).toString()

// Dimension identifiers for the conversions
const CONVERSION_LIST_PREFIX = "dimension_conversion_list"
export const DIMENSION_CONVERSION_LIST_IDENTIFIED_BY = `${CONVERSION_LIST_PREFIX}_identified_by`
export const DIMENSION_CONVERSION_LIST_SESSION_PARAM = `${CONVERSION_LIST_PREFIX}_session_param`
export const DIMENSION_CONVERSION_LIST__UID = `${CONVERSION_LIST_PREFIX}__uid`
export const DIMENSION_CONVERSION_LIST_REPORT_CUSTOM_GROUP_TRACKING = `${CONVERSION_LIST_PREFIX}_report_custom_group_tracking`
export const DIMENSION_CONVERSION_LIST_TS_FIRST_TP = `${CONVERSION_LIST_PREFIX}_ts_first_tp`
